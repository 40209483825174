import { ApiServiceBase } from '@/api/services/ApiServiceBase'
import type { FetchOptions } from 'ofetch'
import type { ApiPaginatedResponse, ApiResponse, Driver, DriverRequest } from '@/api/types'

export default class DriverService extends ApiServiceBase {
  async all(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<Driver[]>> {
    return await this.call('/v1/dpp/drivers', options)
  }

  async retrieve(id: string | number, options?: FetchOptions<'json'>): Promise<ApiResponse<Driver>> {
    return await this.call(`/v1/dpp/drivers/${id}`, options)
  }

  async update(id: string | number, data: DriverRequest): Promise<ApiResponse<Driver>> {
    return await this.call(`/v1/dpp/drivers/${id}`, {
      method: 'put',
      body: data,
    })
  }
}
