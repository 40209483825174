import { ApiServiceBase } from '@/api/services/ApiServiceBase'
import type { FetchOptions } from 'ofetch'
import type { ApiPaginatedResponse, ApiResponse, Payout } from '@/api/types'

export default class PayoutService extends ApiServiceBase {
  async all(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<Payout[]>> {
    return await this.call('/v1/dpp/payouts', options)
  }

  async download(options?: FetchOptions<'blob'>): Promise<Blob> {
    return await this.call('/v1/dpp/payouts', useMerge(options, { query: { download: true } }))
  }

  async retrieve(id: string | number, options?: FetchOptions<'json'>): Promise<ApiResponse<Payout>> {
    return await this.call(`/v1/dpp/payouts/${id}`, options)
  }
}
