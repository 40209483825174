import { ApiServiceBase } from '@/api/services/ApiServiceBase'
import type { FetchOptions } from 'ofetch'
import type { ApiPaginatedResponse, ApiResponse, Payment } from '@/api/types'

export default class PaymentService extends ApiServiceBase {
  async all(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<Payment[]>> {
    return await this.call('/v1/dpp/payments', options)
  }

  async retrieve(id: string | number, options?: FetchOptions<'json'>): Promise<ApiResponse<Payment>> {
    return await this.call(`/v1/dpp/payments/${id}`, options)
  }

  async resendSuccessNotification(id: string | number): Promise<void> {
    return await this.call(`/v1/dpp/payments/${id}/resend-success-notification`, {
      method: 'post',
    })
  }
}
