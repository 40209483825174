import { ApiServiceBase } from '@/api/services/ApiServiceBase'
import type { FetchOptions } from 'ofetch'
import type { ApiPaginatedResponse, ApiResponse, DriverEarnings } from '@/api/types'

export default class DriverEarningsService extends ApiServiceBase {
  async all(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<DriverEarnings[]>> {
    return await this.call(`/v1/dpp/earnings`, options)
  }

  async retrieve(driverId: string | number, options?: FetchOptions<'json'>): Promise<ApiResponse<DriverEarnings>> {
    return await this.call(`/v1/dpp/earnings/${driverId}`, options)
  }
}
