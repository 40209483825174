import { ApiServiceBase } from '@/api/services/ApiServiceBase'
import type { FetchOptions } from 'ofetch'
import type { ApiPaginatedResponse, ApiResponse, NewsPost, NewsPostRequest } from '@/api/types'

export default class NewsPostService extends ApiServiceBase {
  async all(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<NewsPost[]>> {
    return await this.call('/v1/dpp/news-posts', options)
  }

  async create(data: NewsPostRequest): Promise<ApiResponse<NewsPost>> {
    return await this.call('/v1/dpp/news-posts', {
      method: 'post',
      body: useFormData(data),
    })
  }

  async retrieve(id: string | number, options?: FetchOptions<'json'>): Promise<ApiResponse<NewsPost>> {
    return await this.call(`/v1/dpp/news-posts/${id}`, options)
  }

  async update(id: string | number, data: NewsPostRequest): Promise<ApiResponse<NewsPost>> {
    return await this.call(`/v1/dpp/news-posts/${id}`, {
      method: 'post',
      body: useFormData({
        ...data,
        _method: 'put',
      }),
    })
  }

  async delete(id: string | number): Promise<void> {
    return await this.call(`/v1/dpp/news-posts/${id}`, {
      method: 'delete',
    })
  }

  async submit(id: string | number): Promise<ApiResponse<NewsPost>> {
    return await this.call(`/v1/dpp/news-posts/${id}/submit`, {
      method: 'post',
    })
  }
}
