import { ApiServiceBase } from '@/api/services/ApiServiceBase'
import type { FetchOptions } from 'ofetch'
import type { ApiPaginatedResponse, ApiResponse, ManualBlock, ManualBlockRequest } from '@/api/types'

export default class ManualBlockService extends ApiServiceBase {
  async all(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<ManualBlock[]>> {
    return await this.call('/v1/dpp/manual-blocks', options)
  }

  async retrieve(id: string | number, options?: FetchOptions<'json'>): Promise<ApiResponse<ManualBlock>> {
    return await this.call(`/v1/dpp/manual-blocks/${id}`, options)
  }

  async create(data: ManualBlockRequest): Promise<ApiResponse<ManualBlock>> {
    return await this.call('/v1/dpp/manual-blocks', {
      method: 'post',
      body: data,
    })
  }

  async delete(id: string | number): Promise<void> {
    return await this.call(`/v1/dpp/manual-blocks/${id}`, {
      method: 'delete',
    })
  }
}
