import type { FetchOptions } from 'ofetch'
import { ApiServiceBase } from '@/api/services/ApiServiceBase'
import type { Agreement, ApiPaginatedResponse, ApiResponse, Driver } from '@/api/types'

export default class AgreementService extends ApiServiceBase {
  async all(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<Agreement[]>> {
    return await this.call('/v1/dpp/agreements', options)
  }

  async retrieve(id: string | number, options?: FetchOptions<'json'>): Promise<ApiResponse<Agreement>> {
    return await this.call(`/v1/dpp/agreements/${id}`, options)
  }

  async allDrivers(id: string | number, options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<Driver[]>> {
    return await this.call(`/v1/dpp/agreements/${id}/drivers`, options)
  }
}
