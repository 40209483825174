import { ApiServiceBase } from '@/api/services/ApiServiceBase'
import type { FetchOptions } from 'ofetch'
import type { ApiPaginatedResponse, ApiResponse, Invoice } from '@/api/types'

export default class InvoiceSummaryStatementService extends ApiServiceBase {
  async all(fleetId: string | number, options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<Invoice[]>> {
    return await this.call(`/v1/dpp/fleets/${fleetId}/invoice-summary-statements`, options)
  }

  async retrieve(
    fleetId: string | number,
    id: string | number,
    options?: FetchOptions<'json'>
  ): Promise<ApiResponse<Invoice>> {
    return await this.call(`/v1/dpp/fleets/${fleetId}/invoice-summary-statements/${id}`, options)
  }
}
