import { ApiServiceBase } from '@/api/services/ApiServiceBase'
import type { FetchOptions } from 'ofetch'
import type { ApiPaginatedResponse, ApiResponse, WeeklyAccessFee } from '@/api/types'

export default class WeeklyAccessFeeService extends ApiServiceBase {
  async all(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<WeeklyAccessFee[]>> {
    return await this.call('/v1/dpp/weekly-access-fees', options)
  }

  async retrieve(id: string | number, options?: FetchOptions<'json'>): Promise<ApiResponse<WeeklyAccessFee>> {
    return await this.call(`/v1/dpp/weekly-access-fees/${id}`, options)
  }
}
