import { appendHeader } from 'h3'
import { FetchError, type FetchOptions } from 'ofetch'
import { splitCookiesString } from 'set-cookie-parser'
import ApiError from '@/api/models/ApiError'
import type { ApiResponse, ApiServiceContainer, CurrentUser } from '@/api/types'
import AuthenticationService from '@/api/services/AuthenticationService'
import FleetService from '@/api/services/FleetService'
import UserService from '@/api/services/UserService'
import RoleService from '@/api/services/RoleService'
import RegionService from '../services/RegionService'
import AgreementService from '@/api/services/AgreementService'
import BalanceBlockExemptionService from '@/api/services/BalanceBlockExemptionService'
import BookingService from '@/api/services/BookingService'
import DispatchSystemService from '@/api/services/DispatchSystemService'
import DocumentService from '@/api/services/DocumentService'
import DocumentTypeService from '@/api/services/DocumentTypeService'
import DriverEarningsService from '@/api/services/DriverEarningsService'
import DriverService from '@/api/services/DriverService'
import InvoiceChargeService from '@/api/services/InvoiceChargeService'
import InvoiceItemService from '@/api/services/InvoiceItemService'
import InvoiceService from '@/api/services/InvoiceService'
import InvoiceSummaryStatementService from '@/api/services/InvoiceSummaryStatementService'
import ManualBlockService from '@/api/services/ManualBlockService'
import NewsCategoryService from '@/api/services/NewsCategoryService'
import NewsPostService from '@/api/services/NewsPostService'
import PaymentService from '@/api/services/PaymentService'
import PayoutService from '@/api/services/PayoutService'
import VehicleChangeService from '@/api/services/VehicleChangeService'
import WeeklyAccessFeeService from '@/api/services/WeeklyAccessFeeService'

const SECURE_METHODS = new Set(['post', 'delete', 'put', 'patch'])
const UNAUTHENTICATED_STATUSES = new Set([401, 419])
const UNVERIFIED_USER_STATUS = 409
const VALIDATION_ERROR_STATUS = 422
export const UNAUTHORIZED_STATUS = 403
export const UNASSIGNED_FLEET_MESSAGE = 'You must be a member of a fleet to access this resource.'

export const HANDLED_ERROR_STATUSES = new Set([
  ...UNAUTHENTICATED_STATUSES,
  UNVERIFIED_USER_STATUS,
  VALIDATION_ERROR_STATUS,
])

const CSRF_REQUEST_URL = '/sanctum/csrf-cookie'
const CSRF_COOKIE_NAME = 'XSRF-TOKEN'
const CSRF_HEADER_NAME = 'X-XSRF-TOKEN'

export default defineNuxtPlugin(async () => {
  const event = useRequestEvent()
  const config = useRuntimeConfig()
  const { user } = useUser()
  const apiConfig = config.public.api

  async function initUser(getter: () => Promise<ApiResponse<CurrentUser> | null>) {
    try {
      user.value = (await getter())?.data ?? null
    } catch (e) {
      if (e instanceof FetchError && e.response && UNAUTHENTICATED_STATUSES.has(e.response.status)) {
        console.warn('[API initUser] User is not authenticated')
      }
    }
  }

  function buildServerHeaders(headers: HeadersInit | undefined): HeadersInit {
    const csrfToken = useCookie(CSRF_COOKIE_NAME).value
    const clientCookies = useRequestHeaders(['cookie'])

    return {
      ...headers,
      ...(clientCookies.cookie && clientCookies),
      ...(csrfToken && { [CSRF_HEADER_NAME]: csrfToken }),
      Referer: config.public.baseUrl,
      accept: 'application/json',
    }
  }

  async function buildClientHeaders(headers: HeadersInit | undefined): Promise<HeadersInit> {
    await $fetch(CSRF_REQUEST_URL, {
      baseURL: apiConfig.baseUrl,
      credentials: 'include',
    })

    const csrfToken = useCookie(CSRF_COOKIE_NAME).value

    return {
      ...headers,
      ...(csrfToken && { [CSRF_HEADER_NAME]: csrfToken }),
      accept: 'application/json',
    }
  }

  const httpOptions: FetchOptions = {
    baseURL: apiConfig.baseUrl,
    credentials: 'credentials' in Request.prototype ? 'include' : undefined,
    headers: {
      Accept: 'application/json',
    },
    retry: false,

    async onRequest({ options }) {
      if (process.server) {
        options.headers = buildServerHeaders(options.headers)
      }

      if (process.client) {
        const method = options.method?.toLocaleLowerCase() ?? ''

        if (!SECURE_METHODS.has(method)) {
          return
        }

        options.headers = await buildClientHeaders(options.headers)
      }
    },

    onResponse({ response }) {
      if (process.server) {
        const rawCookiesHeader = response.headers.get('set-cookie')

        if (rawCookiesHeader === null) {
          return
        }

        const cookies = splitCookiesString(rawCookiesHeader)

        for (const cookie of cookies) {
          if (event) {
            appendHeader(event, 'set-cookie', cookie)
          }
        }
      }
    },

    async onResponseError({ response }) {
      if (UNAUTHENTICATED_STATUSES.has(response.status)) {
        user.value = null

        await navigateTo(config.public.api.redirects.onAuthOnly)

        return
      }

      if (response.status === UNAUTHORIZED_STATUS && response._data?.message === UNASSIGNED_FLEET_MESSAGE) {
        // Unable to use `navigateTo` here as it doesn't hydrate the page. This is a temporary workaround.
        window.location.href = config.public.api.redirects.onFleetOnly

        return
      }

      if (response.status === UNVERIFIED_USER_STATUS) {
        await navigateTo(config.public.api.redirects.onVerifiedOnly)

        return
      }

      if (response.status === VALIDATION_ERROR_STATUS) {
        throw new ApiError(response._data)
      }
    },
  }

  const client: any = $fetch.create(httpOptions)

  const api: ApiServiceContainer = {
    agreement: new AgreementService(client),
    authentication: new AuthenticationService(client),
    balanceBlockExemption: new BalanceBlockExemptionService(client),
    booking: new BookingService(client),
    dispatchSystem: new DispatchSystemService(client),
    document: new DocumentService(client),
    documentType: new DocumentTypeService(client),
    driverEarnings: new DriverEarningsService(client),
    driver: new DriverService(client),
    fleet: new FleetService(client),
    invoiceCharge: new InvoiceChargeService(client),
    invoiceItem: new InvoiceItemService(client),
    invoice: new InvoiceService(client),
    invoiceSummaryStatement: new InvoiceSummaryStatementService(client),
    manualBlock: new ManualBlockService(client),
    newsCategory: new NewsCategoryService(client),
    newsPost: new NewsPostService(client),
    payment: new PaymentService(client),
    payout: new PayoutService(client),
    region: new RegionService(client),
    role: new RoleService(client),
    user: new UserService(client),
    vehicleChange: new VehicleChangeService(client),
    weeklyAccessFee: new WeeklyAccessFeeService(client),
  }

  if (user.value === null) {
    await initUser(() => api.authentication.user({ query: { include: 'permissions' } }))
  }

  return { provide: { api } }
})
