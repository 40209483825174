import { ApiServiceBase } from '@/api/services/ApiServiceBase'
import type { FetchOptions } from 'ofetch'
import type {
  ApiPaginatedResponse,
  ApiResponse,
  BalanceBlockExemption,
  BalanceBlockExemptionRequest,
} from '@/api/types'

export default class BalanceBlockExemptionService extends ApiServiceBase {
  async all(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<BalanceBlockExemption[]>> {
    return await this.call('/v1/dpp/balance-block-exemptions', options)
  }

  async retrieve(id: string | number, options?: FetchOptions<'json'>): Promise<ApiResponse<BalanceBlockExemption>> {
    return await this.call(`/v1/dpp/balance-block-exemptions/${id}`, options)
  }

  async create(data: BalanceBlockExemptionRequest): Promise<ApiResponse<BalanceBlockExemption>> {
    return await this.call('/v1/dpp/balance-block-exemptions', {
      method: 'post',
      body: data,
    })
  }

  async update(id: string | number, data: BalanceBlockExemptionRequest): Promise<ApiResponse<BalanceBlockExemption>> {
    return await this.call(`/v1/dpp/balance-block-exemptions/${id}`, {
      method: 'put',
      body: data,
    })
  }

  async delete(id: string | number): Promise<void> {
    return await this.call(`/v1/dpp/balance-block-exemptions/${id}`, {
      method: 'delete',
    })
  }
}
