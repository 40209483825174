import { ApiServiceBase } from '@/api/services/ApiServiceBase'
import type { FetchOptions } from 'ofetch'
import type { ApiPaginatedResponse, ApiResponse, Invoice } from '@/api/types'

export default class InvoiceService extends ApiServiceBase {
  async all(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<Invoice[]>> {
    return await this.call('/v1/dpp/invoices', options)
  }

  async retrieve(id: string | number, options?: FetchOptions<'json'>): Promise<ApiResponse<Invoice>> {
    return await this.call(`/v1/dpp/invoices/${id}`, options)
  }
}
