import { ApiServiceBase } from '@/api/services/ApiServiceBase'
import type { FetchOptions } from 'ofetch'
import type {
  ApiPaginatedResponse,
  ApiResponse,
  DeclineVehicleChangeRequest,
  VehicleChange,
  VehicleChangeRequest,
} from '@/api/types'

export default class VehicleChangeService extends ApiServiceBase {
  async all(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<VehicleChange[]>> {
    return await this.call('/v1/dpp/vehicle-changes', options)
  }

  async retrieve(id: string | number, options?: FetchOptions<'json'>): Promise<ApiResponse<VehicleChange>> {
    return await this.call(`/v1/dpp/vehicle-changes/${id}`, options)
  }

  async update(id: string | number, data: VehicleChangeRequest): Promise<ApiResponse<VehicleChange>> {
    return await this.call(`/v1/dpp/vehicle-changes/${id}`, {
      method: 'put',
      body: data,
    })
  }

  async approve(id: string | number): Promise<ApiResponse<VehicleChange>> {
    return await this.call(`/v1/dpp/vehicle-changes/${id}/approve`, {
      method: 'post',
    })
  }

  async decline(id: string | number, data: DeclineVehicleChangeRequest): Promise<ApiResponse<VehicleChange>> {
    return await this.call(`/v1/dpp/vehicle-changes/${id}/decline`, {
      method: 'post',
      body: data,
    })
  }
}
