import { ApiServiceBase } from '@/api/services/ApiServiceBase'
import type { FetchOptions } from 'ofetch'
import type { ApiPaginatedResponse, ApiResponse, DeclineDocumentRequest, Document, DocumentRequest } from '@/api/types'

export default class DocumentService extends ApiServiceBase {
  async all(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<Document[]>> {
    return await this.call('/v1/dpp/documents', options)
  }

  async create(data: DocumentRequest): Promise<ApiResponse<Document>> {
    return await this.call('/v1/dpp/documents', {
      method: 'post',
      body: useFormData(data),
    })
  }

  async retrieve(id: string | number, options?: FetchOptions<'json'>): Promise<ApiResponse<Document>> {
    return await this.call(`/v1/dpp/documents/${id}`, options)
  }

  async update(id: string | number, data: DocumentRequest): Promise<ApiResponse<Document>> {
    return await this.call(`/v1/dpp/documents/${id}`, {
      method: 'post',
      body: useFormData({
        ...data,
        _method: 'put',
      }),
    })
  }

  async delete(id: string | number): Promise<void> {
    return await this.call(`/v1/dpp/documents/${id}`, {
      method: 'delete',
    })
  }

  async approve(id: string | number): Promise<ApiResponse<Document>> {
    return await this.call(`/v1/dpp/documents/${id}/approve`, {
      method: 'post',
    })
  }

  async decline(id: string | number, data: DeclineDocumentRequest): Promise<ApiResponse<Document>> {
    return await this.call(`/v1/dpp/documents/${id}/decline`, {
      method: 'post',
      body: data,
    })
  }
}
