import type { ApiResponse, CurrentUser } from '@/api/types'
import { ApiServiceBase } from '@/api/services/ApiServiceBase'
import type { FetchOptions } from 'ofetch'

export default class AuthenticationService extends ApiServiceBase {
  async logout(): Promise<void> {
    return await this.call('/v1/logout', {
      method: 'post',
    })
  }

  async user(options?: FetchOptions<'json'>): Promise<ApiResponse<CurrentUser>> {
    return await this.call('/v1/user', options)
  }
}
