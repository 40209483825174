import { ApiServiceBase } from '@/api/services/ApiServiceBase'
import type { FetchOptions } from 'ofetch'
import type { ApiPaginatedResponse, ApiResponse, NewsCategory } from '@/api/types'

export default class NewsCategoryService extends ApiServiceBase {
  async all(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<NewsCategory[]>> {
    return await this.call('/v1/dpp/news-categories', options)
  }

  async retrieve(id: string | number, options?: FetchOptions<'json'>): Promise<ApiResponse<NewsCategory>> {
    return await this.call(`/v1/dpp/news-categories/${id}`, options)
  }
}
